


// src/components/CourseDetail.js
import React from 'react';
import { useTranslation } from 'react-i18next';
import { FaRegFilePdf } from "react-icons/fa";

import { useNavigate, useParams } from 'react-router-dom';
import { useGetCourseByIdQuery } from '../../store/services/CourseServiceMain';
import HomeNav from './HomeNav';
import Footer from './FooterLms';

import bbb from "../../assets/images/pdf-svgrepo-com.svg"
const apiimage = process.env.REACT_APP_API_PICTURE;

const CourseDetail = () => {
  const { t } = useTranslation();

  const { id } = useParams();
  const { data: course, error, isLoading } = useGetCourseByIdQuery(id);
  console.log("course", course);
  // const serverBaseURL = 'https://api.wgktrade.com'; // Change this as needed
  const serverBaseURL = apiimage; // Change this as needed

  const navigate = useNavigate()
  if (isLoading) return (
    <>
      <HomeNav />
      <div className="p-4 min-h-[80vh] h-auto mt-[96px] ">
        <p className='bg-green-600 text-white p-4'>
          {/* Loading ........... */}
          
          {t('coursemain-single-loading')}
          </p>

      </div>
      <Footer />
    </>
  );
  if (error) return (
    <>
      <HomeNav />
      <div className="p-4 min-h-[80vh] h-auto mt-[96px] ">
        <p className='bg-red-600 text-white p-4'>
          {/* Error fetching course details. */}
          {t('coursemain-single-error-fetching')}
          </p>

      </div>
      <Footer />
    </>
  );

  const handleVideoClick = (courseId, videoId) => {
    console.log(`Course ID: ${courseId}, Video ID: ${videoId}`);
    // Add your logic here, e.g., navigating to the video page or displaying the video
    navigate(`/video/${course._id}/${videoId}`)
  };

  return (
    <>
      <HomeNav />
      <div className="p-4 mt-[96px]  min-h-[80vh] h-auto">
        {/* <div className="max-w-4xl mx-auto bg-white rounded-lg shadow p-6 mb-6">
          <img src={`${serverBaseURL}${course.thumbnail_url}`} alt={course.title} className="w-full h-64 object-cover rounded-t-lg" />
          <div className="p-4">
            <h2 className="text-3xl font-semibold mb-4">{course.title}</h2>
            <p className="text-lg text-gray-700 mb-4">{course.description}</p>

            <div className=' flex justify-between items-center '>

            <p className="text-white  bg-blue-500 p-4 rounded mr-2">{t('coursemain-single-category')} {course.category}</p>

            <p className="text-white bg-blue-500 p-4 rounded">{t('coursemain-single-price')}:{course.price}</p>

            </div>
            </div>
        </div> */}

<div className="max-w-4xl mx-auto bg-white rounded-lg shadow-lg hover:shadow-xl transition-shadow duration-300 mb-6  border border-gray-300 hover:border-blue-500">
    <img 
      src={`${serverBaseURL}${course.thumbnail_url}`} 
      alt={course.title} 
      className="w-full h-64 object-cover rounded-t-lg"
    />
    <div className="p-6 " >
      <h2 className="text-3xl font-semibold text-gray-800 mb-4">{course.title}</h2>
      <p className="text-lg text-gray-600 mb-4">{course.description}</p>
      <div className="flex justify-between items-center space-x-4">
        <p className="flex items-center text-white bg-gradient-to-r from-blue-400 to-blue-600 p-4 rounded-lg shadow-md hover:shadow-lg transition-shadow duration-300">
          <span className="mr-2 text-lg material-icons">category</span> 
          {t('coursemain-single-category')} {course.category}
        </p>
        <p className="flex items-center text-white bg-gradient-to-r from-green-400 to-green-600 p-4 rounded-lg shadow-md hover:shadow-lg transition-shadow duration-300">
          {t('coursemain-single-price')}: {course.price}
        </p>
      </div>
    </div>
  </div>

        {/* Video List */}
        <div className="max-w-4xl mx-auto bg-white rounded-lg shadow p-6 border border-gray-300 hover:border-blue-500">
          <h3 className="text-2xl font-semibold mb-4">
            {/* Videos */}
            
            {t('coursemain-single-videos')}
            </h3>
          



            {course.video_list && course.video_list.length > 0 ? (
                  course.video_list.map((video) => (
              <div key={video._id}>
      {/* Render Supertitle Above the Box */}
      {video.supertitle && video.supertitle.trim() && (
  <div className="sm:font-bold  mb-5 mt-5 rounded  bg-yellow-300 p-3 text-black rounded w-full text-center sm:text-left">
        <span className=''>Sub Title : {video.supertitle}</span>  
        </div>
      )}

{video.pdf && (
              <div className="bg-green-200 font-bold pdf-link flex justify-between items-center  hover:bg-blue-500 hover:text-white  cursor-pointer mb-4 p-4 border rounded" onClick={() => handleVideoClick(course._id, video._id)}>
<img className='h-[40px]' src={bbb}/>


               <p>PDF : {video.pdf.name}</p>  

               <button
          className={`px-3 py-1 rounded ${
            video.pdf.courseType === "free" ? 'bg-green-500' : 'bg-red-500'
          } text-white`}
        >
          {video.pdf.courseType === "free"
            ? t('coursemain-single-free')
            : t('coursemain-single-paid')}
        </button>
              </div>
            )}

      {/* Video Box */}
      <div
        className="flex justify-between items-center mb-4 p-4 border rounded hover:bg-blue-500 hover:text-white  cursor-pointer"
        onClick={() => handleVideoClick(course._id, video._id)}
      >
        <span className="text-lg font-medium">{video.title}</span>
        <button
          className={`px-3 py-1 rounded ${
            video.is_free ? 'bg-green-500' : 'bg-red-500'
          } text-white`}
        >
          {video.is_free
            ? t('coursemain-single-free')
            : t('coursemain-single-paid')}
        </button>
      </div>
    </div>
  ))
) : (
  <p className="text-gray-500">
    {t('coursemain-single-no-videos-available')}
  </p>
)}












          
        </div>
      </div>
      <Footer />
    </>
  );
};

export default CourseDetail;





// {course.video_list && course.video_list.length > 0 ? (
//   course.video_list.map((video) => (
//     <div
//       key={video._id}
//       className="flex items-center mb-4 p-4 border rounded hover:bg-gray-100 cursor-pointer"
//       onClick={() => handleVideoClick(course._id, video._id)} // Make the div clickable
//     >
                   

//       <i className="fas fa-video text-blue-500 mr-3"></i>
//       <div className="flex-1 ">
//         <span className="text-lg">{video.title}</span>
//       </div>
//       <button
//         className={`px-3 py-1 rounded ${video.is_free ? 'bg-green-500' : 'bg-red-500'} text-white`}
//       >
//         {/* {video.is_free ? 'Free' : 'Paid'} */}
//         {video.is_free ? t('coursemain-single-free')   :     t('coursemain-single-paid')}
//       </button>
//     </div>
//   ))
// ) : (
//   <p className="text-gray-500">
//     {/* No videos available for this course. */}
//     {t('coursemain-single-no-videos-available')}
//     </p>
// )}
