
// import { useState, useEffect } from "react";
// import { Link, useNavigate, useParams } from "react-router-dom";
// import { useDispatch } from "react-redux";
// import ReactQuill from "react-quill";
// import toast, { Toaster } from "react-hot-toast";
// import h2p from "html2plaintext";
// import "react-quill/dist/quill.snow.css";
// import ScreenHeader from "../../components/ScreenHeader";
// import Wrapper from "./Wrapper";
// import { useUpdateVideobyAdminMutation } from "../../store/services/productService";
// import Spinner from "../../components/Spinner";
// import { setSuccess } from "../../store/reducers/globalReducer";
// import { useTranslation } from 'react-i18next';
// const apiimage = process.env.REACT_APP_API_PICTURE;

// const AdminPdfVideo = () => {
//   const { t } = useTranslation();
//   const { id } = useParams();
//   const serverBaseURL = apiimage

//   const dispatch = useDispatch();
//   const navigate = useNavigate();
//   const [state, setState] = useState({
//     title: '',
//     price: 0,
//     pdfAdmin: '', // Store PDF file
// });
// const [preview, setPreview] = useState(null); // For PDF preview


 


//   const handleInput = (e) => {
//     setState({ ...state, [e.target.name]: e.target.value });
//   };



//   const pdfHandle = e => {
//     if (e.target.files.length !== 0) {
//         const file = e.target.files[0];
//         setState({...state, pdfAdmin: file});
        
//         // Preview PDF (Here we display the file name as a preview)
//         const reader = new FileReader();
//         reader.onloadend = () => {
//             setPreview(reader.result); // For previewing
//         }
//         reader.readAsDataURL(file);
//     }
// };



// const [createNewProduct, response] = useUpdateVideobyAdminMutation();
    
// const createPro = e => {
//     e.preventDefault();
//     const formData = new FormData();
//     formData.append('data', JSON.stringify(state));  // Include title and price
//     formData.append('pdfAdmin', state.pdfAdmin);    // Include PDF file
    
//     // Retrieve and append instructor-id from local storage
//     const instructorId = localStorage.getItem('instructor-id');
//     if (instructorId) {
//         formData.append('instructorId', instructorId);
//     } else {
//         toast.error(t('dashboard-teacher-createcourse-errorInstructorIdMissing'));
//         return;
//     }

//     createNewProduct(formData);
// };

// useEffect(() => {
//     if (response?.isSuccess) {
//         dispatch(setSuccess(t('dashboard-teacher-createcourse-success')));
//         navigate('/dashboard/teacher');
//     }
// }, [response?.isSuccess, dispatch, navigate, t]);



//   return (
//     <Wrapper>
//       <ScreenHeader>
//         <Link to="/dashboard/get/admin/course" className="btn-dark">
//           <i className="bi bi-arrow-left-short"></i> {t('admin-dashboard-course-edit-back')}
//         </Link>
//       </ScreenHeader>
//       <Toaster position="top-right" reverseOrder={true} />
     
//       <div className="flex flex-wrap -mx-3">
//                 <form className="w-full xl:w-8/12 p-3" onSubmit={createPro}>
//                     <div className="flex flex-wrap">
//                         <div className="w-full md:w-6/12 p-3">
//                             <label htmlFor="title" className="label">
//                                 {t('dashboard-teacher-createcourse-title')}
//                             </label>
//                             <input 
//                                 type="text" 
//                                 name="title" 
//                                 className="form-control" 
//                                 id="title" 
//                                 placeholder="Title..." 
//                                 onChange={handleInput} 
//                                 value={state.title} 
//                             />
//                         </div>
//                         <div className="w-full md:w-6/12 p-3">
//                             <label htmlFor="price" className="label">
//                                 {t('dashboard-teacher-createcourse-price')}
//                             </label>
//                             <input 
//                                 type="number" 
//                                 name="price" 
//                                 className="form-control" 
//                                 id="price" 
//                                 placeholder="Price..." 
//                                 onChange={handleInput} 
//                                 value={state.price} 
//                             />
//                         </div>
//                         <div className="w-full p-3">
//                             <label htmlFor="pdfAdmin" className="label">
//                                 {/* {t('dashboard-teacher-createcourse-pdfAdmin')} */}
//                                 Pdf FILE
//                             </label>
//                             <input 
//                                 type="file" 
//                                 name="pdfAdmin" 
//                                 id="pdfAdmin" 
//                                 className="input-file" 
//                                 accept="application/pdf" 
//                                 onChange={pdfHandle} 
//                             />
//                         </div>
//                         {preview && (
//                             <div className="w-full p-3">
//                                 <embed src={preview} width="100%" height="500px" type="application/pdf" />
//                             </div>
//                         )}
//                         <div className="w-full p-3">
//                             <input 
//                                 type="submit" 
//                                 value={response.isLoading ? t('dashboard-teacher-createcourse-loading') : t('dashboard-teacher-createcourse-saveButton')} 
//                                 disabled={response.isLoading} 
//                                 className="btn btn-indigo" 
//                             />
//                         </div>
//                     </div>
//                 </form>
//             </div>




//     </Wrapper>
//   );
// };

// export default AdminPdfVideo;















import { useState, useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import ReactQuill from "react-quill";
import toast, { Toaster } from "react-hot-toast";
import h2p from "html2plaintext";
import "react-quill/dist/quill.snow.css";
import ScreenHeader from "../../components/ScreenHeader";
import Wrapper from "./Wrapper";
import { useCPdfaAdminMutation } from "../../store/services/productService";
import Spinner from "../../components/Spinner";
import { setSuccess } from "../../store/reducers/globalReducer";
import { useTranslation } from 'react-i18next';
const apiimage = process.env.REACT_APP_API_PICTURE;

const AdminPdfVideo = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const serverBaseURL = apiimage;

  const dispatch = useDispatch();
  const navigate = useNavigate();
  
  const [state, setState] = useState({
    title: '',
    price: 0,
    pdfAdmin: '', // Store PDF file
    courseType: '', // New state for Free/Paid selection
  });

  const [preview, setPreview] = useState(null); // For PDF preview

  const handleInput = (e) => {
    setState({ ...state, [e.target.name]: e.target.value });
  };

  const handleCourseType = (e) => {
    setState({ ...state, courseType: e.target.value }); // Update course type selection
  };

  const pdfHandle = e => {
    if (e.target.files.length !== 0) {
      const file = e.target.files[0];
      setState({ ...state, pdfAdmin: file });

      // Preview PDF (Here we display the file name as a preview)
      const reader = new FileReader();
      reader.onloadend = () => {
        setPreview(reader.result); // For previewing
      };
      reader.readAsDataURL(file);
    }
  };

  const [createNewProduct, response] = useCPdfaAdminMutation();

  const createPro = e => {
    e.preventDefault();
    if (!state.courseType) {
    //   toast.error(t('dashboard-teacher-createcourse-errorSelectType'));
      toast.error("Coure Free Or Paid Please Select");
      return; // Ensure course type is selected
    }

    const formData = new FormData();
    formData.append('data', JSON.stringify(state));  // Include title, price, and course type
    formData.append('pdfAdmin', state.pdfAdmin);    // Include PDF file

    // Retrieve and append instructor-id from local storage
    const instructorId = localStorage.getItem('instructor-id');
    if (instructorId) {
      formData.append('instructorId', instructorId);
    } else {
      toast.error(t('dashboard-teacher-createcourse-errorInstructorIdMissing'));
      return;
    }

    createNewProduct(formData);
  };

  useEffect(() => {
    if (response?.isSuccess) {
      dispatch(setSuccess(t('dashboard-teacher-createcourse-success')));
      navigate('/dashboard/teacher');
    }
  }, [response?.isSuccess, dispatch, navigate, t]);

  return (
    <Wrapper>
      <ScreenHeader>
        <Link to="/dashboard/get/admin/course" className="btn-dark">
          <i className="bi bi-arrow-left-short"></i> {t('admin-dashboard-course-edit-back')}
        </Link>
      </ScreenHeader>
      <Toaster position="top-right" reverseOrder={true} />

      <div className="flex flex-wrap -mx-3">
        <form className="w-full xl:w-8/12 p-3" onSubmit={createPro}>
          <div className="flex flex-wrap">
            <div className="w-full md:w-6/12 p-3">
              <label htmlFor="title" className="label">
                {t('dashboard-teacher-createcourse-title')}
              </label>
              <input
                type="text"
                name="title"
                className="form-control"
                id="title"
                placeholder="Title..."
                onChange={handleInput}
                value={state.title}
              />
            </div>
            <div className="w-full md:w-6/12 p-3">
              <label htmlFor="price" className="label">
                {t('dashboard-teacher-createcourse-price')}
              </label>
              <input
                type="number"
                name="price"
                className="form-control"
                id="price"
                placeholder="Price..."
                onChange={handleInput}
                value={state.price}
              />
            </div>
            <div className="w-full p-3">
              <label htmlFor="pdfAdmin" className="label">
                Pdf FILE
              </label>
              <input
                type="file"
                name="pdfAdmin"
                id="pdfAdmin"
                className="input-file"
                accept="application/pdf"
                onChange={pdfHandle}
              />
            </div>
            {preview && (
              <div className="w-full p-3">
                <embed src={preview} width="100%" height="500px" type="application/pdf" />
              </div>
            )}
            <div className="w-full p-3">
              <label className="label">{t('dashboard-teacher-createcourse-courseType')}</label>
              <div className="flex items-center space-x-4">
                <label className="flex items-center">
                  <input
                    type="radio"
                    name="courseType"
                    value="free"
                    checked={state.courseType === 'free'}
                    onChange={handleCourseType}
                    className="form-radio"
                  />
                  {/* <span className="ml-2">{t('dashboard-teacher-createcourse-free')}</span> */}
                  <span className="ml-2">Free</span>
                </label>
                <label className="flex items-center">
                  <input
                    type="radio"
                    name="courseType"
                    value="paid"
                    checked={state.courseType === 'paid'}
                    onChange={handleCourseType}
                    className="form-radio"
                  />
                  {/* <span className="ml-2">{t('dashboard-teacher-createcourse-paid')}</span> */}
                  <span className="ml-2">Paid</span>
                </label>
              </div>
            </div>
            <div className="w-full p-3">
              <input
                type="submit"
                value={response.isLoading ? t('dashboard-teacher-createcourse-loading') : t('dashboard-teacher-createcourse-saveButton')}
                disabled={response.isLoading}
                className="btn btn-indigo"
              />
            </div>
          </div>
        </form>
      </div>
    </Wrapper>
  );
};

export default AdminPdfVideo;

