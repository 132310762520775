import { useState, useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import toast, { Toaster } from "react-hot-toast";
import "react-quill/dist/quill.snow.css";
import ScreenHeaderTeacher from "../../components/ScreenHeaderTeacher";
import WrapperTeacher from "./WrapperTeacher";
import { 
  useUpdatePdfTeacherSingleMutation, 
  useFetchPdfDetailsSignleForTeacherQuery 
} from "../../store/services/productServiceTeacher";
import Spinner from "../../components/Spinner";
import { setSuccess } from "../../store/reducers/globalReducer";
import { useTranslation } from "react-i18next";

const apiimage = process.env.REACT_APP_API_UR;

const TeacherPdfEdit = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const serverBaseURL = apiimage;

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [state, setState] = useState({
    title: "",
    price: 0,
    courseType: "free", // Default value
    pdfAdmin: null,
  });

  const [preview, setPreview] = useState(null); // For PDF preview

  const { data: pdfData, isFetching } = useFetchPdfDetailsSignleForTeacherQuery(id); // Fetch current PDF details
  const [updatePdf, response] = useUpdatePdfTeacherSingleMutation();

  console.log("pdfdata",pdfData)
  useEffect(() => {
    if (pdfData?.course) {
        console.log("asdasdasdasdasdasd",pdfData?.course)
        console.log("pdfData.course.title",pdfData.course.title)
      setState({
        title: pdfData.course.title || "",
        price: pdfData.course.price || 0,
        courseType: pdfData.course.courseType || "free", // Default to "free" if undefined
        pdfAdmin: null, // No file initially loaded
      });
      setPreview(`${serverBaseURL}/${pdfData.course.pdflink || ""}`);
    }
  }, [pdfData, serverBaseURL]);

  const handleInput = (e) => {
    setState({ ...state, [e.target.name]: e.target.value });
  };

  const handleCourseType = (e) => {
    setState({ ...state, courseType: e.target.value });
  };

  const pdfHandle = (e) => {
    if (e.target.files.length !== 0) {
      const file = e.target.files[0];
      setState({ ...state, pdfAdmin: file });

      const reader = new FileReader();
      reader.onloadend = () => {
        setPreview(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleUpdate = (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("data", JSON.stringify({ 
      title: state.title, 
      price: state.price, 
      courseType: state.courseType 
    }));
    if (state.pdfAdmin) {
      formData.append("pdfAdmin", state.pdfAdmin);
    }

    const instructorId = localStorage.getItem("instructor-id");
    if (instructorId) {
      formData.append("instructorId", instructorId);
    } else {
      toast.error("Instructor ID missing.");
      return;
    }

    updatePdf({ id, formData });
  };

  useEffect(() => {
    if (response?.isSuccess) {
      dispatch(setSuccess("PDF updated successfully!"));
      navigate("/dashboard/teacher");
    } else if (response?.error) {
      toast.error("Error updating PDF. Please try again.");
    }
  }, [response?.isSuccess, response?.error, dispatch, navigate]);

  // if (isFetching) return <Spinner />;

  return (
    <WrapperTeacher>
      <ScreenHeaderTeacher>
        <Link to="/dashboard/teacher/all/pdf" className="btn-dark">
          <i className="bi bi-arrow-left-short"></i> {t("admin-dashboard-course-edit-back")}
        </Link>
      </ScreenHeaderTeacher>
      <Toaster position="top-right" reverseOrder={true} />

      <div className="flex flex-wrap -mx-3">
        <form className="w-full xl:w-8/12 p-3" onSubmit={handleUpdate}>
          <div className="flex flex-wrap">
            <div className="w-full md:w-6/12 p-3">
              <label htmlFor="title" className="label">
                {t("dashboard-teacher-createcourse-title")}
              </label>
              <input
                type="text"
                name="title"
                className="form-control"
                id="title"
                placeholder="Title..."
                onChange={handleInput}
                value={state.title}
              />
            </div>
            <div className="w-full md:w-6/12 p-3">
              <label htmlFor="price" className="label">
                {t("dashboard-teacher-createcourse-price")}
              </label>
              <input
                type="number"
                name="price"
                className="form-control"
                id="price"
                placeholder="Price..."
                onChange={handleInput}
                value={state.price}
              />
            </div>
            <div className="w-full p-3">
              <label htmlFor="pdfAdmin" className="label">
                Pdf FILE
              </label>
              <input
                type="file"
                name="pdfAdmin"
                id="pdfAdmin"
                className="input-file"
                accept="application/pdf"
                onChange={pdfHandle}
              />
            </div>
            {preview && (
              <div className="w-full p-3">
                <embed src={preview} width="100%" height="500px" type="application/pdf" />
              </div>
            )}
            <div className="w-full p-3">
              <label className="label">{t("dashboard-teacher-createcourse-courseType")}</label>
              <div className="flex items-center space-x-4">
                <label className="flex items-center">
                  <input
                    type="radio"
                    name="courseType"
                    value="free"
                    checked={state.courseType === "free"}
                    onChange={handleCourseType}
                    className="form-radio"
                  />
                  <span className="ml-2">Free</span>
                </label>
                <label className="flex items-center">
                  <input
                    type="radio"
                    name="courseType"
                    value="paid"
                    checked={state.courseType === "paid"}
                    onChange={handleCourseType}
                    className="form-radio"
                  />
                  <span className="ml-2">Paid</span>
                </label>
              </div>
            </div>
            <div className="w-full p-3">
              <input
                type="submit"
                value={response.isLoading ? "Updating..." : "Update PDF"}
                disabled={response.isLoading}
                className="btn btn-indigo"
              />
            </div>
          </div>
        </form>
      </div>
    </WrapperTeacher>
  );
};

export default TeacherPdfEdit;
