


import { useState, useEffect } from "react";
import { useTranslation } from 'react-i18next';

import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import toast, { Toaster } from 'react-hot-toast';
import 'react-quill/dist/quill.snow.css';
import ScreenHeaderTeacher from "../../components/ScreenHeaderTeacher";
import WrapperTeacher from "./WrapperTeacher";
// import { useAllCategoriesQuery } from "../../store/services/categoryServiceTeacher";
import { useCProductMutation ,useAllCategoriesQuery} from "../../store/services/productServiceTeacher";
import Spinner from "../../components/Spinner";
import ImagesPreview from "../../components/ImagesPreview";
import { setSuccess } from "../../store/reducers/globalReducer";
import ReactQuill from "react-quill";






const CreateProduct = () => {
    const { t } = useTranslation(); // Initialize translation hook

    const {data = [], isFetching} = useAllCategoriesQuery();
    const [value, setValue] = useState('');
    const [state, setState] = useState({
        title: '',
        price: 0,
        category: '',
        image1: '',
        instructorId:''

    });
    const [preview, setPreview] = useState({
        image1: ''
    });

    const imageHandle = e => {
        if(e.target.files.length !== 0) {
            setState({...state, [e.target.name]: e.target.files[0]});
            const reader = new FileReader();
            reader.onloadend = () => {
                setPreview({...preview, [e.target.name]: reader.result})
            }
            reader.readAsDataURL(e.target.files[0]);
        }
    };

    const handleInput = e => {
        setState({...state, [e.target.name]: e.target.value});
    };

    const [createNewProduct, response] = useCProductMutation();
    console.log('Your response', response);

    const createPro = e => {
        e.preventDefault();
        const formData = new FormData();
        formData.append('data', JSON.stringify(state));
        formData.append('description', value);
        formData.append('image1', state.image1);
    
        // Retrieve and append instructor-id from local storage
        const instructorId = localStorage.getItem('instructor-id');
        if (instructorId) {
            formData.append('instructorId', instructorId);
        } else {
            toast.error(t('dashboard-teacher-createcourse-errorInstructorIdMissing'));

            // toast.error("Instructor ID is missing");
            return;
        }
    
        createNewProduct(formData);
    };
    
    // useEffect(() => {
    //     if (response?.error?.data?.errors && Array.isArray(response.error.data.errors)) {
    //         response.error.data.errors.forEach(err => {
    //             toast.error(err.msg);
    //         });
    //     } else if (response?.error) {
    //         toast.error("An unexpected error occurred.");
    //     }
    // }, [response?.error?.data?.errors]);

    useEffect(() => {
        if (response?.error?.data?.errors && Array.isArray(response.error.data.errors)) {
            response.error.data.errors.forEach(err => {
                toast.error(t('dashboard-teacher-createcourse-errorUnexpected'));
            });
        } else if (response?.error) {
            toast.error(t('dashboard-teacher-createcourse-errorUnexpected'));
        }
    }, [response?.error?.data?.errors, t]);


     const dispatch = useDispatch();
    const navigate = useNavigate();
    
    // useEffect(() => {
    //     if (response?.isSuccess) {
    //         dispatch(setSuccess(response.data.msg));
    //         console.log("this dcall  asdasd")
    //         navigate('/dashboard/teacher');
    //     }
    // }, [response?.isSuccess]);

    useEffect(() => {
        if (response?.isSuccess) {
            dispatch(setSuccess(t('dashboard-teacher-createcourse-success')));
            navigate('/dashboard/teacher');
        }
    }, [response?.isSuccess, dispatch, navigate, t]);
    

    // useEffect(() => {
    //     if(!response.isSuccess) {
    //         response?.error?.data?.errors.map(err => {
    //             toast.error(err.msg);
    //         });
    //     }
    // }, [response?.error?.data?.errors]);

    useEffect(() => {
        if (!response.isSuccess) {
            response?.error?.data?.errors.map(err => {
                toast.error(t('dashboard-teacher-createcourse-errorUnexpected'));
            });
        }
    }, [response?.error?.data?.errors, t]);

    // const dispatch = useDispatch();
    // const navigate = useNavigate();
    // useEffect(() => {
    //     if(response?.isSuccess) {
    //         dispatch(setSuccess(response?.data?.msg));
    //         navigate('/dashboard/products');
    //     }
    // }, [response?.isSuccess]);

    return (
        <WrapperTeacher>
            <ScreenHeaderTeacher>
                <Link to="/dashboard/teacher" className="btn-dark">
                    <i className="bi bi-arrow-left-short"></i>
                     {/* Course List */}
                     {t('dashboard-teacher-createcourse-back')}
                </Link>
            </ScreenHeaderTeacher>
            <Toaster position="top-right" reverseOrder={true} />
            <div className="flex flex-wrap -mx-3">
                <form className="w-full xl:w-8/12 p-3" onSubmit={createPro}>
                    <div className="flex flex-wrap">
                        <div className="w-full md:w-6/12 p-3">
                            <label htmlFor="title" className="label">
                                {/* Title */}
                                {t('dashboard-teacher-createcourse-title')}
                                </label>
                            <input type="text" name="title" className="form-control" id="title" placeholder="Title..." onChange={handleInput} value={state.title} />
                        </div>
                        <div className="w-full md:w-6/12 p-3">
                            <label htmlFor="price" className="label">
                                {/* Price */}
                                {t('dashboard-teacher-createcourse-price')}
                                </label>
                            <input type="number" name="price" className="form-control" id="price" placeholder="Price..." onChange={handleInput} value={state.price} />
                        </div>
                        <div className="w-full md:w-6/12 p-3">
                            <label htmlFor="categories" className="label">
                                {/* Categories */}
                                {t('dashboard-teacher-createcourse-category')}
                                </label>
                            {!isFetching ? data?.categories?.length > 0 && (
                                <select name="category" id="categories" className="form-control" onChange={handleInput} value={state.category}>
                                    <option value="">
                                        {/* Choose category */}
                                        {t('dashboard-teacher-createcourse-category')}
                                        </option>
                                    {data?.categories?.map(category => (
                                        <option value={category.name} key={category._id}>{category.name}</option>
                                    ))}
                                </select>
                            ) : <Spinner />}
                        </div>
                        <div className="w-full p-3">
                            <label htmlFor="image1" className="label">
                                {/* Image 1 */}
                                {t('dashboard-teacher-createcourse-image')}
                                </label>
                            <input type="file" name="image1" id="image1" className="input-file" onChange={imageHandle} />
                        </div>
                        <div className="w-full p-3">
                            <label htmlFor="description" className="label">
                                {/* Description */}
                                {t('dashboard-teacher-createcourse-description')}
                                </label>
                            <ReactQuill theme="snow" id="description" value={value} onChange={setValue} placeholder="Description..." />
                        </div>
                        <div className="w-full p-3">
                            {/* <input type="submit" value={response.isLoading ? 'Loading...' : 'Save Product'} disabled={response.isLoading} className="btn btn-indigo" /> */}
                            <input type="submit" value={response.isLoading ? t('dashboard-teacher-createcourse-loading') : t('dashboard-teacher-createcourse-saveButton')} disabled={response.isLoading} className="btn btn-indigo" />

                        
                        </div>
                    </div>
                </form>
                <div className="w-full xl:w-4/12 p-3">
                    {/* <ImagesPreview url={preview.image1} heading="Image 1" /> */}
                    <ImagesPreview url={preview.image1} heading={t('dashboard-teacher-createcourse-image')} />

                </div>
            </div>
        </WrapperTeacher>
    );
};

export default CreateProduct;



