import React, { useState, useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import toast, { Toaster } from 'react-hot-toast';
import 'react-quill/dist/quill.snow.css';
import ScreenHeaderTeacher from "../../components/ScreenHeaderTeacher";
import WrapperTeacher from "./WrapperTeacher";
import {useFetchCoursesForPDFsQuery, useFetchVideosForPdfQuery,useSubmitPdftAtachToVidoMutation,useFetchPdfforteacherQuery} from "../../store/services/productServiceTeacher";
import Spinner from "../../components/Spinner";
import { useTranslation } from 'react-i18next';


const TeacherPdfAttachVideo = ({ courseId }) => {
    const teacherId = localStorage.getItem('instructor-id'); // Assuming you store the teacher ID in local storage
    const { t } = useTranslation(); // Localization hook

    const navigate = useNavigate();

    const [state, setState] = useState({
        courseId: "",
        videoId: "",
        pdfId: "",
    });


    const { data: coursesAndPDFs, isFetching: isFetchingCourses } = useFetchCoursesForPDFsQuery({ page : 1, teacherId });
    console.log("isFetchingCourses",isFetchingCourses)

    // Fetch PDFs associated with the teacher
    const { data: pdfs, isFetching: isFetchingPdfs } = useFetchPdfforteacherQuery(teacherId);
    console.log("pdfs",pdfs)
    console.log("isFetchingPdfs",isFetchingPdfs)

    const { data: videos, refetch: refetchVideos } = useFetchVideosForPdfQuery(state.courseId, { skip: !state.courseId });
    const [submitSelection, { isLoading: isSubmitting }] = useSubmitPdftAtachToVidoMutation();

    console.log("coursesAndPDFs",coursesAndPDFs)
    console.log("videos",videos)

    const handleInputChange = (e) => {
        setState({ ...state, [e.target.name]: e.target.value });
    };

    // const handleSubmit = async (e) => {
    //     e.preventDefault();
    //     try {
    //         const response = await submitSelection(state).unwrap();
    //         toast.success("Data submitted successfully!");
    //         navigate("/dashboard/teacher");
    //     } catch (err) {
    //         toast.error("Error submitting data. Please try again.");
    //     }
    // };


    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const payload = {
                courseId: state.courseId,
                videoId: state.videoId,
                pdfId: state.pdfId,
                teacherId,
            };
            console.log("this is 33333333",payload)
            const response = await submitSelection(payload).unwrap();

            toast.success("Data submitted successfully!");
            navigate("/dashboard/teacher");
        } catch (err) {
            console.log("this is 22222222222222222222",err)
            toast.error("Error submitting data. Please try again.",err.message);
        }
    };

    useEffect(() => {
        if (state.courseId) refetchVideos();
    }, [state.courseId]);


    return (
        <WrapperTeacher>
            <ScreenHeaderTeacher>
                <Link to="/dashboard/teacher" className="btn-dark">
                    <i className="bi bi-arrow-left-short"></i>
                     {/* Back */}
                     {t('tvideo-upload-back')}

                </Link>
            </ScreenHeaderTeacher>
            <Toaster position="top-right" reverseOrder={true} />

            <form className="w-full p-3" onSubmit={handleSubmit}>
                <div className="flex flex-wrap -mx-3">

                    {/* a section  start */}
                    <div className="w-full p-3">
                        <label htmlFor="courseId" className="label">Select Course</label>
                        {!isFetchingCourses ? (
                            <select name="courseId" id="courseId" className="form-control" onChange={handleInputChange} value={state.courseId}>
                                <option value="">Choose Course</option>
                                {coursesAndPDFs?.products?.map((course) => (
                                    <option value={course._id} key={course._id}>{course.title}</option>
                                ))}
                            </select>
                        ) : <Spinner />}
                    </div>
                   {/* a section  end */}


                    {/* b section  start */}

                    <div className="w-full p-3">
                        <label htmlFor="videoId" className="label">Select Video</label>
                        <select name="videoId" id="videoId" className="form-control" onChange={handleInputChange} value={state.videoId} disabled={!state.courseId}>
                            <option value="">Choose Video</option>
                            {videos?.video_list?.map((video) => (
                                <option value={video._id} key={video._id}>{video.title}</option>
                            ))}
                        </select>
                        
                    </div>
                   {/* b section  end */}



                    {/* c section  start */}

                    <div className="w-full p-3">
                        <label htmlFor="pdfId" className="label">Select PDF</label>
                        {!isFetchingPdfs ? (
 
                        <select name="pdfId" id="pdfId" className="form-control" onChange={handleInputChange} value={state.pdfId}>
                            <option value="">Choose PDF</option>
                            {pdfs?.pdfs?.map((pdf) => (
                                <option value={pdf._id} key={pdf._id}>{pdf.title}</option>
                            ))}
                        </select>
                    ) : <Spinner />}
                    </div>
                   {/* c section  end */}



                    <div className="w-full p-3">
                        <button type="submit" className="btn btn-indigo" disabled={isSubmitting}>
                            {isSubmitting ? "Submitting..." : "Submit"}
                        </button>
                    </div>
                </div>
            </form>
          
        </WrapperTeacher>
    );
};

export default TeacherPdfAttachVideo;







