
import { useState, useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import ReactQuill from "react-quill";
import toast, { Toaster } from "react-hot-toast";
import h2p from "html2plaintext";
import "react-quill/dist/quill.snow.css";
import ScreenHeader from "../../components/ScreenHeader";
import Wrapper from "./Wrapper";
import { useUpdateVideobyAdminMutation, useGetSingleVideoAdminQuery, useGetadminAllCategoriesQuery } from "../../store/services/productService";
import Spinner from "../../components/Spinner";
import { setSuccess } from "../../store/reducers/globalReducer";
import { useTranslation } from 'react-i18next';
const apiimage = process.env.REACT_APP_API_PICTURE;

const AdminUpdateVideo = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const serverBaseURL = apiimage

  const { data: product, isFetching: fetching } = useGetSingleVideoAdminQuery(id);
  console.log("single video",product)

  const [state, setState] = useState({
    title: "",
    supertitle: "",
  });

 


  const handleInput = (e) => {
    setState({ ...state, [e.target.name]: e.target.value });
  };



  const [updateVideo, response] = useUpdateVideobyAdminMutation();

  const updateVideoHandler = (e) => {
    e.preventDefault();
    const payload = {
      title: state.title,
      supertitle: state.supertitle,
    };
    console.log("id",id)

    updateVideo({ videoId: id, ...payload });
  };



  useEffect(() => {
    if (!response.isSuccess && response?.error?.data?.errors) {
      response.error.data.errors.forEach((err) => {
        toast.error(err.msg);
      });
    }
  }, [response?.error?.data?.errors]);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if (response?.isSuccess) {
      dispatch(setSuccess(t('admin-dashboard-course-edit-success')));
      navigate("/dashboard/get/admin/course");
    }
  }, [response?.isSuccess]);

  useEffect(() => {
    if (!fetching && product?.product) {
      setState({
        title: product.product.title || "",
        supertitle: product.product.supertitle || "",
      });
    }
  }, [product, fetching]);

  return (
    <Wrapper>
      <ScreenHeader>
        <Link to="/dashboard/get/admin/course" className="btn-dark">
          <i className="bi bi-arrow-left-short"></i> {t('admin-dashboard-course-edit-back')}
        </Link>
      </ScreenHeader>
      <Toaster position="top-right" reverseOrder={true} />
      {!fetching ? (
        <div className="flex flex-wrap -mx-3 bg-yellow-300">
          <form className="w-full xl:w-8/12 p-3" onSubmit={updateVideoHandler}>
            <h3 className="pl-3 capitalize text-lg font-medium text-gray-400">
              {t('admin-dashboard-course-edit-title')}
            </h3>
            <div className="flex flex-wrap">



              <div className="w-full md:w-6/12 p-3">
                <label htmlFor="title" className="label">
                  {t('admin-dashboard-course-edit-title')}
                </label>
                <input
                  type="text"
                  name="title"
                  className="form-control"
                  id="title"
                  placeholder={t('admin-dashboard-course-edit-title-placeholder')}
                  onChange={handleInput}
                  value={state.title}
                />
              </div>


              <div className="w-full p-3">
                <label htmlFor="supertitle" className="label">
                  {/* {t('admin-dashboard-video-edit-supertitle')} */}
                  Super Title
                </label>
                <input
                  type="text"
                  name="supertitle"
                  className="form-control"
                  id="supertitle"
                //   placeholder={t('admin-dashboard-video-edit-supertitle-placeholder')}
                  placeholder="super title"
                  onChange={handleInput}
                  value={state.supertitle}
                />
              </div>


          

            

           

              <div className="w-full p-3">
                <input
                  type="submit"
                  value={response.isLoading ? t('admin-dashboard-course-edit-loading') : t('admin-dashboard-course-edit-submit')}
                  disabled={response.isLoading}
                  className="btn btn-indigo"
                />
              </div>
            </div>
          </form>
        </div>
      ) : (
        <Spinner />
      )}
    </Wrapper>
  );
};

export default AdminUpdateVideo;
