import { useEffect } from "react";
import { useTranslation } from 'react-i18next'; // Import the useTranslation hook

import { Link, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import toast, { Toaster } from 'react-hot-toast';
import { clearMessage } from "../../store/reducers/globalReducer";
import Wrapper from "./Wrapper";
import {useGetInstructorsQuery,useDeleteInstructorMutation } from "../../store/services/productService";

// import { useGetInstructorsQuery, useDeleteInstructorMutation } from "../../store/services/instructorService"; // Updated service
import ScreenHeader from "../../components/ScreenHeader";
import Spinner from "../../components/Spinner";
import Pagination from "../../components/Pagination";

const Instructors = () => {
   const { t } = useTranslation(); // Initialize the translation hook

   let { page } = useParams();
   if (!page) {
      page = 1;
   }
   const { data = [], isFetching } = useGetInstructorsQuery(page); // Updated query
   const { success } = useSelector(state => state.globalReducer);
   const dispatch = useDispatch();
   console.log("data?.instructors?",data?.instructors)

   useEffect(() => {
      if (success) {
         toast.success(success);
      }
      return () => {
         dispatch(clearMessage());
      };
   }, [success, dispatch]);

   const [delInstructor, response] = useDeleteInstructorMutation(); // Updated mutation

   const deleteInstructor = id => {
      if (window.confirm("Are you sure you want to delete this instructor?")) {
         delInstructor(id);
      }
   };

   return (
      <Wrapper>
         <ScreenHeader>
            <Link to="/dashboard/instructors" className="btn-dark">
            {/* Well Come To Teacher */}
            {t('admin-dashboard-teacher-get-welcome_teacher')}

            
            </Link>
            <Toaster position="top-right" />
         </ScreenHeader>
         {!isFetching ? data?.instructors?.length > 0 ? <div>
            <table className="w-full bg-gray-900 rounded-md">
               <thead>
                  <tr className="border-b border-gray-800 text-left">
                     <th className="p-3 uppercase text-sm font-medium text-gray-500">
                        {/* Name */}
                        {t('admin-dashboard-teacher-get-name')}

                        
                        </th>
                     <th className="p-3 uppercase text-sm font-medium text-gray-500"
                     >
                        {/* Email */}
                     
                     {t('admin-dashboard-teacher-get-email')}

                     </th>
                     <th className="p-3 uppercase text-sm font-medium text-gray-500">
                        {/* Mobile */}
                        {t('admin-dashboard-teacher-get-mobile')}

                        
                        </th>
                     <th className="p-3 uppercase text-sm font-medium text-gray-500">
                        {/* Courses */}
                        {t('admin-dashboard-teacher-get-courses')}

                        
                        </th>
                     <th className="p-3 uppercase text-sm font-medium text-gray-500">
                        {/* Balance */}
                        {t('admin-dashboard-teacher-get-balance')}

                        
                        </th>
                     <th className="p-3 uppercase text-sm font-medium text-gray-500">
                        {/* Withdrawable */}
                        {t('admin-dashboard-teacher-get-withdrawable')}

                        
                        </th>

                        <th className="p-3 uppercase text-sm font-medium text-gray-500">
                        {/* status */}
                        {t('admin-dashboard-teacher-get-status')}

                        
                        </th>

                     <th className="p-3 uppercase text-sm font-medium text-gray-500">
                        {/* Edit */}
                        {t('admin-dashboard-teacher-get-edit')}

                        
                        </th>
                     <th className="p-3 uppercase text-sm font-medium text-gray-500">
                        {/* Delete */}
                        {t('admin-dashboard-teacher-get-delete')}

                        </th>
                  </tr>
               </thead>
               <tbody>
                  {data?.instructors?.map(instructor => (
                     <tr className="odd:bg-gray-800" key={instructor._id}>
                        <td className="p-3 capitalize text-sm font-normal text-gray-400">{instructor.name}</td>
                        <td className="p-3 text-sm font-normal text-gray-400">{instructor.email}</td>
                        <td className="p-3 text-sm font-normal text-gray-400">{instructor.mobile_number}</td>
                        <td className="p-3 text-sm font-normal text-gray-400">{instructor.total_courses}</td>
                        <td className="p-3 text-sm font-normal text-gray-400">MAD:{instructor.balance}</td>
                        <td className="p-3 text-sm font-normal text-gray-400">MAD:{instructor.withdrawable}</td>
                        <td className=" text-sm font-normal text-white  border 1px solid border-green-400">{instructor.status ? instructor.status : "Pending"}</td>

                        <td className="p-3 capitalize text-sm font-normal text-gray-400 ">

                           <Link to={`/dashboard/instructor/update/${instructor._id}`} className="btn btn-warning">
                           
                           {/* Edit */}
                           {t('admin-dashboard-teacher-get-edit')}

                           </Link>
                        </td>
                        <td className="p-3 capitalize text-sm font-normal text-gray-400">
                           <span className="btn btn-danger cursor-pointer" onClick={() => deleteInstructor(instructor._id)}>
                              
                              {/* Delete */}
                              {t('admin-dashboard-teacher-get-delete')}

                              </span>
                        </td>
                     </tr>
                  ))}
               </tbody>
            </table>
            <Pagination page={parseInt(page)} perPage={data.perPage} count={data.count} path="dashboard/instructors" />
         </div> : 'No instructors!' : <Spinner />}
      </Wrapper>
   );
};

export default Instructors;
