import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useState } from "react";

// import logo from '../assets/images/logoteacher.png'
import logo from '../assets/images/mainlogo.png'


const Sidebar = ({ side, closeSidebar }) => {
  const { t } = useTranslation(); // Using the useTranslation hook
  const { i18n } = useTranslation();


  const [isLangDropdownOpen, setIsLangDropdownOpen] = useState(false);


  const handleLanguageChange = (lng) => {
    console.log("lng",lng)

    i18n.changeLanguage(lng);
    console.log("lng",lng)
    setIsLangDropdownOpen(false); // Close the dropdown after selecting a language
    // setIsOpen(!isOpen);

  };

    // Toggle language dropdown
    const handleLangDropdownToggle = () => {
      setIsLangDropdownOpen(!isLangDropdownOpen);
    };


  return (
    <div
      className={`fixed top-0 ${side} sm:left-0 w-64 h-screen bg-gray-800 z-10 transition-all`}
    >
      <i
        className="bi bi-x-lg absolute top-4 right-4 sm:hidden block cursor-pointer text-lg"
        onClick={closeSidebar}
      ></i>
      <div className="bg-[#476CC4]   ">
        {/* <img src="/logo.svg" alt="logo" /> */}
        <img src={logo} alt={t("admin-dashboard-sidebar-logoAlt")} className="h-[80px] w-[130px]"  />

      </div>

           

      <div className="overflow-y-auto h-[calc(100vh-4rem)]">
<ul className="mt-4">


      <li className="px-4 cursor-pointer transition-all py-3 text-white flex items-center hover:bg-gray-600">
  {/* Language Dropdown */}
  <div className="relative bg-red-500" >
              {/* <div className="relative" ref={langDropdownRef}> */}
                <button onClick={handleLangDropdownToggle} className="text-black hover:bg-white hover:text-black px-2 py-2 rounded-md text-sm font-medium">
                  Language
                </button>
                {isLangDropdownOpen && (
                  <div className="absolute left-0 mt-2 w-48 bg-white rounded-md shadow-lg z-20">
                    <button onClick={() => handleLanguageChange('en')} className="text-black hover:bg-gray-200 block px-4 py-2 text-sm">English</button>
                    <button onClick={() => handleLanguageChange('ar')} className="text-black hover:bg-gray-200 block px-4 py-2 text-sm">العربية</button>
                    <button onClick={() => handleLanguageChange('fr')} className="text-black hover:bg-gray-200 block px-4 py-2 text-sm">française</button>
                  </div>
                )}
              </div>
          </li>




        <li className="px-4 cursor-pointer transition-all py-3 text-white flex items-center hover:bg-gray-600">
          <i className="bi bi-card-list mr-2 inline-block text-lg"></i>{" "}
          <Link to="/dashboard/products" className="text-base capitalize">
            {/* Students */}
            {t("admin-dashboard-sidebar-students")}

          </Link>
        </li>








        
        <li className="px-4 cursor-pointer transition-all py-3 text-white flex items-center hover:bg-gray-600">
          <i className="bi bi-card-list mr-2 inline-block text-lg"></i>{" "}
          <Link to="/dashboard/instructors" className="text-base capitalize">
            {/* Teacher */}
            {t("admin-dashboard-sidebar-teacher")}


          </Link>
        </li>

        <li className="px-4 cursor-pointer transition-all py-3 text-white flex items-center hover:bg-gray-600">
          <i className="bi bi-card-list mr-2 inline-block text-lg"></i>{" "}
          <Link to="/dashboard/get/admin/course" className="text-base capitalize">
            {/* Courses */}
            {t("admin-dashboard-sidebar-courses")}

          </Link>
        </li>



        <li className="px-4 cursor-pointer transition-all py-3 text-white flex items-center hover:bg-gray-600">
          <i className="bi bi-bag-check mr-2 inline-block text-lg"></i>{" "}
          <Link to="/dashboard/student/search" className="text-base capitalize">
            {/* Search Student */}
            {t("admin-dashboard-sidebar-searchStudent")}

          </Link>
        </li>
        <li className="px-4 cursor-pointer transition-all py-3 text-white flex items-center hover:bg-gray-600">
          <i className="bi bi-people-fill mr-2 inline-block text-lg"></i>{" "}
          <Link to="/dashboard/teacher/search" className="text-base capitalize">
          {/* Search Teacher */}
          {t("admin-dashboard-sidebar-searchTeacher")}


          </Link>
        </li>
        <li className="px-4 cursor-pointer transition-all py-3 text-white flex items-center hover:bg-gray-600">
          <i className="bi bi-bar-chart mr-2 inline-block text-lg"></i>{" "}
          <Link to="/dashboard/course/search" className="text-base capitalize">
            {/* Search Course */}
            {t("admin-dashboard-sidebar-searchCourse")}

          </Link>
        </li>
        <li className="px-4 cursor-pointer transition-all py-3 text-white flex items-center hover:bg-gray-600">
          <i className="bi bi-bar-chart mr-2 inline-block text-lg"></i>{" "}
          <Link to="/dashboard/categories" className="text-base capitalize">
            {/* categories */}
            {t("admin-dashboard-sidebar-categories")}

          </Link>
        </li>
        

        <li className="px-4 cursor-pointer transition-all py-3 text-white flex items-center hover:bg-gray-600">
          <i className="bi bi-bar-chart mr-2 inline-block text-lg"></i>{" "}
          <Link to="/dashboard/get/admin/package" className="text-base capitalize">
              {/* all Package */}
              {t("admin-dashboard-sidebar-allPackage")}

          </Link>
        </li>

        <li className="px-4 cursor-pointer transition-all py-3 text-white flex items-center hover:bg-gray-600">
          <i className="bi bi-bar-chart mr-2 inline-block text-lg"></i>{" "}
          <Link to="/dashboard/create/admin/package" className="text-base capitalize">
            {/* Create stud Package */}
            {t("admin-dashboard-sidebar-createStudPackage")}

          </Link>
        </li>

        <li className="px-4 cursor-pointer transition-all py-3 text-white flex items-center hover:bg-gray-600 mb-3">
          <i className="bi bi-bar-chart mr-2 inline-block text-lg"></i>{" "}
          <Link to="/dashboard/admin/payment" className="text-base capitalize">
            {/* Show Payment Web */}
            {t("admin-dashboard-sidebar-showPaymentWeb")}

          </Link>
        </li>
      </ul>


      </div>
      
    </div>
  );
};
export default Sidebar;
