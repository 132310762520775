
import React, { useState, useEffect, useRef } from 'react';
import { Link, useParams } from 'react-router-dom';

import * as pdfjsLib from 'pdfjs-dist/webpack';

import { useTranslation } from 'react-i18next';

import HomeNav from '../HomeNav';
import { useGetVideosByCourseIdQuery, useGetVideoByIdQuery, useGetCourseByIdQuery } from '../../../store/services/CourseServiceMain'; // Adjust import to include course query
import Footer from '../FooterLms';
import SecureVideoPlayer from './SecureVideoPlayer';

import bbb from "../../../assets/images/pdf-svgrepo-com.svg"


const apiUrl = process.env.REACT_APP_API_URL;

const VideoPage = () => {

  const { t } = useTranslation();

  // Inject CSS for hiding download button
  const customStyles = `
    video::-internal-media-controls-download-button {
      display: none;
    }
    video::-webkit-media-controls-enclosure {
      overflow: hidden;
    }
  `;

  const { courseid, videoid } = useParams();
  const userDataString = localStorage.getItem('user');
  const userData = userDataString ? JSON.parse(userDataString) : null;

  const userid = userData ? userData._id : null; // Get userId from localStorage
  const [selectedVideo, setSelectedVideo] = useState("");
  const [message, setMessage] = useState('');
  const [selectedVideoTitle, setSelectedVideoTitle] = useState(''); // Store the video title
  const [isVideoFree, setIsVideoFree] = useState(false); // Store if the video is free

  const [selectedPdfUrl, setSelectedPdfUrl] = useState("");
  const [selectedPdfTitle, setSelectedPdfTitle] = useState(''); // Store the video title




  const { data: courseData } = useGetCourseByIdQuery(courseid); // Fetch course data
  const { data: courseVideos } = useGetVideosByCourseIdQuery(courseid);
  const { data: videoData, error: videoError, isFetching } = useGetVideoByIdQuery({ courseid, videoid, userid });

  console.log("courseVideos", courseVideos)
  console.log("videoData", videoData)




  // Replace with your server base URL
  const serverBaseURL = apiUrl; // Change this as needed
  // const serverBaseURL = 'https://api.wgktrade.com'; // Change this as needed

  useEffect(() => {
    if (videoError) {
      handleVideoError(videoError);
    } else if (videoData) {
      handleVideoData(videoData);
    }
  }, [videoData, videoError, userid]);




  const handleVideoError = (error) => {
    if (error.status === 403) {
      setMessage(t('please-pay-for-course'));
    } else if (error.status === 401) {
      setMessage(t('please-log-in'));
    } else {
      setMessage(t('error-fetching-video'));
    }
    setSelectedVideo(null);
  };



  const handleVideoData = (data) => {
    const { videos, hasPaid } = data;

    if (!userid) {
      setMessage('Please log in to view this video.');
      setSelectedVideo(null);
      setSelectedPdfUrl(null)

    } else if (!videos.is_free && !hasPaid) {

      setMessage('Please pay for the course to view this video.');
      setSelectedVideo(null);
      setSelectedPdfUrl(null)

    } else {
      setSelectedPdfUrl(null)

      setSelectedVideo(`${serverBaseURL}${videos.video_url}`);
      setSelectedVideoTitle(videos.title); // Set the video title
      setIsVideoFree(videos.is_free); // Set if the video is free
      setMessage('');


    }
  };

  const handleVideoClick = (video) => {
    if (!userid) {
      setMessage('Please log in to view this video.');
      setSelectedVideo(null);
    } else if (!video.is_free && (!videoData || !videoData.hasPaid)) {


      setMessage('Please pay for the course to view this video.');
      setSelectedVideo(null);
    } else {
      setSelectedPdfUrl(null)
      setSelectedPdfTitle(null);


      setSelectedVideo(`${serverBaseURL}${video.video_url}`);
      setSelectedVideoTitle(video.title);
      setIsVideoFree(video.is_free);
      setMessage('');
    }
  };


  const handlePdfClick = (video) => {
    console.log("PDF DATA ", video)
    if (!userid) {
      setMessage('Please log in to view this video.');
      setSelectedVideo(null);
      setSelectedPdfUrl(null)
    } else if (!video.courseType === "free" && (!videoData || !videoData.hasPaid)) {


      setMessage('Please pay for the course to view this Pdf File.');
      setSelectedPdfUrl(null)

      setSelectedVideo(null);
    } else {

      setSelectedVideo(null);


      setSelectedPdfUrl(`${serverBaseURL}${video.pdflink}`);

      console.log("final url to view", selectedPdfUrl)
      setSelectedPdfTitle(video.title);
      setSelectedVideoTitle(null);


      setIsVideoFree(video.courseType === "free" ? true : false);
      setMessage('');
    }
  };









  if (isFetching) return (
    <>
      <HomeNav />
      <div className='min-h-[80vh] h-auto mt-[96px]  '>Loading...</div>
      <Footer />

    </>

  );


  return (
    <>
      <style>{customStyles}</style>

      <HomeNav />

      <div className="flex flex-col md:flex-row mt-[96px] min-h-[100vh] h-auto ">

        {/* Sidebar */}
        <div className="w-full md:w-1/3 bg-gray-100 p-4 overflow-y-auto  rounded-lg shadow-lg hover:shadow-xl transition-shadow duration-300  border border-gray-300 hover:border-blue-500" style={{ maxHeight: 'calc(100vh - 96px)' }}>
          {courseData && (
            <div className="mb-4">
              <h2 className="text-xl font-bold bg-yellow-300 rounded p-2 ">{courseData.title}</h2> {/* Display course title */}
            </div>
          )}

          {courseVideos && courseVideos.map((video) => (

            <>


              {video.supertitle && video.supertitle.trim() && (
                <div className="sm:font-bold  mb-5 mt-5 rounded  bg-yellow-300 p-3 text-black rounded w-full text-center sm:text-left">
                  <span className=''>
                    Sub Title: {video.supertitle}
                  </span>
                </div>
              )}



              {video.pdf && (
                <div className="bg-green-200 font-bold pdf-link flex justify-between items-center  hover:bg-blue-500 hover:text-white  cursor-pointer mb-4 p-3 border rounded" >
                  <img className='h-[40px]' src={bbb} />


                  <p>PDF : {video.pdf.title}</p>

                  <button
                    onClick={(e) => {
                      e.preventDefault();
                      handlePdfClick(video.pdf);
                    }}
                    className={`px-3 py-1 rounded ${video.pdf.courseType === "free" ? 'bg-green-500' : 'bg-red-500'} text-white`}
                  >
                    {video.pdf.courseType === "free"
                      ? t('coursemain-single-free')
                      : t('coursemain-single-paid')}
                  </button>
                </div>
              )}





              <div key={video._id} className="flex justify-between items-center p-4 mb-4 bg-white rounded shadow hover:bg-blue-500 hover:text-white  cursor-pointer">
                <div className="font-bold">{video.title}</div>
                <button
                  onClick={(e) => {
                    e.preventDefault();
                    handleVideoClick(video);
                  }}
                  className={`px-2 py-1 text-sm rounded ${video.is_free ? 'bg-green-500 text-white' : 'bg-red-500 text-white'
                    }`}
                >

                  {video.is_free ? t('coursemain-single-free') : t('coursemain-single-paid')}

                </button>
              </div>



            </>

          ))}
        </div>

        {/* Video Player */}
        <div className="w-full md:w-2/3 p-4">
          {selectedVideoTitle && (
            <div className="mb-2 flex justify-between items-center">
              <h3 className="text-lg font-bold">{selectedVideoTitle}</h3> {/* Display selected video title */}
              <button
                className={`px-2 py-1 text-sm rounded ${isVideoFree ? 'bg-green-500 text-white' : 'bg-red-500 text-white'
                  }`}
              >
                {/* {isVideoFree ? 'Free' : 'Paid'} */}

                {isVideoFree ? t('coursemain-single-free') : t('coursemain-single-paid')}

                {/* {video.is_free ? t('coursemain-single-paid') : t('coursemain-single-free')} */}

              </button>
            </div>
          )}



          {selectedPdfTitle && (
            <div className="mb-2 flex justify-between items-center">
              <h3 className="text-lg font-bold">{selectedPdfTitle}</h3> {/* Display selected video title */}
              <button
                className={`px-2 py-1 text-sm rounded text-white ${isVideoFree ? 'bg-green-500' : 'bg-red-500'}`}
              >
                {/* {isVideoFree ? 'Free' : 'Paid'} */}

                {isVideoFree ? t('coursemain-single-free') : t('coursemain-single-paid')}

                {/* {video.is_free ? t('coursemain-single-paid') : t('coursemain-single-free')} */}

              </button>
            </div>
          )}

          {message && <div className="flex flex-col items-center justify-center mx-auto p-4">
            <div className="mb-4 text-center font-bold text-white bg-red-600 p-4">
              {message}
            </div>
            <div className="text-red-500 mb-4 text-center font-bold">



              {t('video-content-locked-message').split('+212601938528')[0]}
              <span className="ltr-phone" style={{ direction: "ltr", unicodeBidi: 'bidi-override' }} >+212601938528</span>
              {t('video-content-locked-message').split('+212601938528')[1]}

              {/* {t('video-content-locked-message')} */}
            </div>
            <div className="text-red-500 mb-4 text-center font-bold">
              {t('video-already-registered-message')}{' '}
              <Link to="/login" className="text-blue-500 hover:text-black rounded-md">
                {t('video-log-in-message')}
              </Link>
            </div>
            <div className="text-red-500 mb-4 text-center">
              {/* {t('video-contact-register-message')} */}

              {t('video-contact-register-message').split('+212601938528')[0]}
              <span className="ltr-phone" style={{ direction: "ltr", unicodeBidi: 'bidi-override' }} >+212601938528</span>
              {t('video-contact-register-message').split('+212601938528')[1]}

            </div>
            <div className="mb-4">
              <button className="bg-blue-500 hover:bg-red-500 text-white px-4 py-2 rounded">
                <Link to="/register">{t('video-contact-to-register-message')}</Link>
              </button>
            </div>
          </div>


          }



          {/* {message && <div className="text-red-500">{message}</div>} */}
          {selectedVideo && !message && (
            <video
              controls
              controlsList="nodownload"
              key={selectedVideo}
              onContextMenu={(e) => e.preventDefault()}
              style={{ userSelect: 'none' }}

            >
              <source src={selectedVideo} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          )}



          {selectedPdfUrl && !message && (

            <>
              <div className="relative" onContextMenu={(e) => e.preventDefault()}>
                {/* Header Div */}
                <div className="bg-yellow-300 p-4 absolute top-0 sm:left-[70%] left-[60%] right-0 z-10">
                  <h1 className="text-black font-bold text-center">PDF</h1>
                </div>

                {/* PDF Viewer */}
                
                <embed
                  src={selectedPdfUrl}
                  width="100%"
                  height="600px"
                  style={{ border: "none" }}
                  className='  user-select: none; /* Prevent text selection */
'
                

                ></embed>

<iframe
                  src={selectedPdfUrl}
                  width="100%"
                  height="600px"
                  style={{ border: "none" }}
                  className='  user-select: none; /* Prevent text selection */
'
                

                ></iframe>



<a href={selectedPdfUrl} target="_blank">Download PDF</a>
              </div>
 </>




          )}











        </div>




      </div>
      <Footer />
    </>
  );
};

export default VideoPage;









