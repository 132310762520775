
import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

import { Link, useNavigate, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import toast, { Toaster } from "react-hot-toast";
import { useGetCourseQuery, useDeleteVideoMutation } from "../../store/services/productServiceTeacher";
import WrapperTeacher from "./WrapperTeacher";
import ScreenHeaderTeacher from "../../components/ScreenHeaderTeacher";
import Spinner from "../../components/Spinner";

const CourseVideos = () => {
  const { t } = useTranslation(); // Use the useTranslation hook

  const { id } = useParams();
  const { data: course, isFetching: fetching } = useGetCourseQuery(id);
  const [deleteVideo, { isSuccess, isError, error }] = useDeleteVideoMutation();
  console.log("course",course)
  const [videos, setVideos] = useState([]);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if (!fetching && course) {
      setVideos(course.video_list || []);
    }
  }, [course, fetching]);

  useEffect(() => {
    if (isSuccess) {
      toast.success("Video deleted successfully!");
      navigate("/dashboard/teacher");

    }
    if (isError) {
      console.error("Error deleting video:", error);
      toast.error(error?.message || "An error occurred!");
    }
  }, [isSuccess, isError, error]);

  const handleDeleteVideo = (videoId) => {
    if (window.confirm("Are you sure you want to delete this video?")) {
      deleteVideo({ id, videoId });
    }
  };

  return (
    <WrapperTeacher>
      <ScreenHeaderTeacher>
        <Link to={`/dashboard/teacher`} className="btn-dark">
          <i className="bi bi-arrow-left-short"></i> 
          {/* Back to Course */}

          {t("dashboard-teacher-editVideo-backToCourse")}
        </Link>
      </ScreenHeaderTeacher>
      <Toaster position="top-right" reverseOrder={true} />
      {!fetching ? (
        <div className="flex flex-wrap -mx-3 bg-yellow-300">
          <div className="w-full xl:w-8/12 p-3">
            <h3 className="pl-3 capitalize text-lg font-bold text-black ">
              {/* Videos for Course: <span className="bg-blue-500 p-1 rounded">{course.title}</span> */}
              {t("dashboard-teacher-editVideo-videosForCourse")}: <span className="bg-blue-500 p-1 rounded">{course.title}</span>
            </h3>
            <ul className="list-none p-0">
              {videos.length > 0 ? (
                videos.map((video) => (
                  <li key={video._id} className="flex justify-between items-center border-b border-gray-300 p-3 text-black font-bold">
                    <span>{video.title}</span>
                    <button
                      onClick={() => handleDeleteVideo(video._id)}
                      className="btn btn-red hover:text-black bg-red-600"
                    >
                      {/* Delete */}

                      {t("dashboard-teacher-editVideo-delete")}
                    </button>
                  </li>
                ))
              ) : (
                // <p>No videos available for this course.</p>
                <p>{t("dashboard-teacher-editVideo-noVideosAvailable")}</p>

              )}
            </ul>
          </div>
        </div>
      ) : (
        <Spinner />
      )}
    </WrapperTeacher>
  );
};

export default CourseVideos;
