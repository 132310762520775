import React, { useState, useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import toast, { Toaster } from 'react-hot-toast';
import 'react-quill/dist/quill.snow.css';
import ScreenHeaderTeacher from "../../components/ScreenHeaderTeacher";
import WrapperTeacher from "./WrapperTeacher";
import { useUploadVideoMutation, useGetProductsQuery,useGetProductsVideoQuery } from "../../store/services/productServiceTeacher";
import Spinner from "../../components/Spinner";
import VideosPreview from "../../components/VideosPreview";
import { setSuccess } from "../../store/reducers/globalReducer";
import ReactQuill from "react-quill";
import { useTranslation } from 'react-i18next';

const CHUNK_SIZE = 1024 * 1024 * 5; // 5 MB

const UploadVideoForm = ({ courseId }) => {
    const { page = 1 } = useParams(); // Default page to 1 if undefined
    const teacherId = localStorage.getItem('instructor-id'); // Assuming you store the teacher ID in local storage
    const { t } = useTranslation(); // Localization hook

    const { data = {}, isFetching } = useGetProductsVideoQuery({ page, teacherId });
    const [value, setValue] = useState('');
    const [state, setState] = useState({
        title: '',
        supertitle:"",
        description: '',
        course: courseId || '', // Default to passed courseId if provided
        video: null,
        isFree: false,
        instructorId: ''
    });
    const [preview, setPreview] = useState({
        video: null
    });

    const [progress, setProgress] = useState(0); // State for progress
    const [uploading, setUploading] = useState(false); // State to track if uploading


    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [uploadVideo, { isLoading, isSuccess, error }] = useUploadVideoMutation();

    const handleInput = (e) => {
        setState({ ...state, [e.target.name]: e.target.value });
    };

    const videoHandle = (e) => {
        if (e.target.files.length !== 0) {
            setState({ ...state, video: e.target.files[0] });
            const reader = new FileReader();
            reader.onloadend = () => {
                setPreview({ ...preview, video: reader.result });
            };
            reader.readAsDataURL(e.target.files[0]);
        }
    };

    const uploadChunks = async (file) => {
        const totalChunks = Math.ceil(file.size / CHUNK_SIZE);
        for (let i = 0; i < totalChunks; i++) {
            const start = i * CHUNK_SIZE;
            const end = Math.min(start + CHUNK_SIZE, file.size);
            const chunk = file.slice(start, end);

            const formData = new FormData();
            formData.append('video', chunk);
            formData.append('chunkNumber', i + 1);
            formData.append('totalChunks', totalChunks); // Add total chunks for the backend to know when the upload is complete
            formData.append('title', state.title);
            formData.append('supertitle', state.supertitle);
            formData.append('description', value);
            formData.append('course', state.course);
            formData.append('is_free', state.isFree);
            formData.append('instructorId', localStorage.getItem('instructor-id'));

            // try {
            //     await uploadVideo(formData).unwrap();
            // } catch (err) {
            //     throw err; // Propagate error to be caught in handleSubmit
            // }



            try {
                setUploading(true); // Set uploading state
                await uploadVideo(formData).unwrap();
                const newProgress = Math.round(((i + 1) / totalChunks) * 100); // Calculate progress
                setProgress(newProgress); // Update progress state
            } catch (err) {
                // Retry logic in case of network failure
                const retryCount = 3; // Number of retries
                for (let retry = 0; retry < retryCount; retry++) {
                    try {
                        await uploadVideo(formData).unwrap();
                        break; // Exit retry loop if successful
                    } catch (err) {
                        if (retry === retryCount - 1) throw err; // If last attempt fails, throw error
                    }
                }
            }

        }
    };



    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            if (state.video) {
                await uploadChunks(state.video);
                toast.success(t('tvideo-upload-success'));
            } else {
                toast.error(t('tvideo-upload-no-file'));
            }
        } catch (err) {
            toast.error(t('tvideo-upload-error'));
        }
    };

 


    useEffect(() => {
        if (error?.data?.errors && Array.isArray(error.data.errors)) {
            error.data.errors.forEach(err => {
                toast.error(err.msg);
            });
        } else if (error) {
            toast.error(t('tvideo-upload-error'));
        }
    }, [error]);



    useEffect(() => {
        if (isSuccess) {
            dispatch(setSuccess(t('tvideo-upload-success')));
            navigate(`/dashboard/teacher`);
        }
    }, [isSuccess]);

    return (
        <WrapperTeacher>
            <ScreenHeaderTeacher>
                <Link to="/dashboard/teacher" className="btn-dark">
                    <i className="bi bi-arrow-left-short"></i>
                     {/* Back */}
                     {t('tvideo-upload-back')}

                </Link>
            </ScreenHeaderTeacher>
            <Toaster position="top-right" reverseOrder={true} />
            <div className="flex flex-wrap -mx-3">
                <form className="w-full xl:w-8/12 p-3" onSubmit={handleSubmit} encType="multipart/form-data">
                    <div className="flex flex-wrap">



                    <div className="w-full md:w-6/12 p-3">
                            <label htmlFor="supertitle" className="label">
                                Super Title
                                {/* {t('tvideo-upload-supertitle')} */}
                                </label>
                            <input type="text" name="supertitle" className="form-control" id="supertitle" placeholder="supertitle..." onChange={handleInput} value={state.supertitle} />
                        </div>




                        <div className="w-full md:w-6/12 p-3">
                            <label htmlFor="title" className="label">
                                {/* Title */}
                                {t('tvideo-upload-title')}
                                </label>
                            <input type="text" name="title" className="form-control" id="title" placeholder="Title..." onChange={handleInput} value={state.title} />
                        </div>







                        <div className="w-full md:w-6/12 p-3">
                            <label htmlFor="course" className="label">
                                {/* Course */}
                                {t('tvideo-upload-course')}
                                </label>
                            {!isFetching ? data.products?.length > 0 && (
                                <select name="course" id="course" className="form-control" onChange={handleInput} value={state.course}>
                                    <option value="">
                                        {/* Choose course */}
                                        {t('tvideo-upload-choose-course')}
                                        </option>
                                    {data.products.map(product => (
                                        <option value={product._id} key={product._id}>{product.title}</option>
                                    ))}
                                </select>
                            ) : <Spinner />}
                        </div>
                        <div className="w-full p-3">
                            <label htmlFor="video" className="label">
                                {/* Video File */}
                                {t('tvideo-upload-video-file')}
                                </label>
                            <input type="file" name="video" id="video" className="input-file" onChange={videoHandle} required />
                        </div>
                        <div className="w-full p-3">
                            <label htmlFor="description" className="label">
                                {/* Description */}
                                {t('tvideo-upload-description')}
                                </label>
                            <ReactQuill theme="snow" id="description" value={value} onChange={setValue} placeholder="Description..." />
                        </div>
                        <div className="w-full p-3">
                            <label className="label">
                                {/* Free Video */}
                                {t('tvideo-upload-free-video')}

                            </label>
                            <input type="checkbox" name="isFree" checked={state.isFree} onChange={(e) => setState({ ...state, isFree: e.target.checked })} />
                        </div>
                        <div className="w-full p-3">
                            <input type="submit" value={isLoading ? 'Loading...' : 'Upload Video'} disabled={isLoading} className="btn btn-indigo" />
                        </div>
                    </div>
                     {/* Progress Bar */}
                     {uploading && (
                        <div className="w-full p-3">
                            <div className="bg-gray-200 h-4 rounded">
                                <div className="bg-blue-600 h-4 rounded" style={{ width: `${progress}%` }}></div>
                            </div>
                            <p className="text-sm text-gray-700">{progress}% Uploaded</p>
                        </div>
                    )}
                </form>
                <div className="w-full xl:w-4/12 p-3">
                    <VideosPreview url={preview.video} heading="Video Preview" />
                </div>
            </div>
        </WrapperTeacher>
    );
};

export default UploadVideoForm;







