import {createApi, fetchBaseQuery} from "@reduxjs/toolkit/query/react";
const apiUrl = process.env.REACT_APP_API_URL;
const productService = createApi({
    reducerPath: 'products',
    tagTypes: 'products',
    baseQuery: fetchBaseQuery({
        // baseUrl: 'http://localhost:5000/api/'
        baseUrl: `${apiUrl}/api/`    ,
        prepareHeaders: (headers, {getState}) => {
            const reducers = getState();
            const token = reducers?.authReducer?.adminToken;
            console.log(token)
            headers.set('authorization', token ? `Bearer ${token}` : '');
            return headers;
        }
    }),
    endpoints: (builder) => {
        return {
            cProduct: builder.mutation({
                query: (data) => {
                   return {
                       url: '/create-product',
                       method: 'POST',
                       body: data
                   }
                },
                invalidatesTags: ['products']
            }),



            cPdfaAdmin: builder.mutation({
              query: (formData) => {
                return {
                  url: '/create/admin/pdf',
                  method: 'POST',
                  body: formData,
                }
              },
              // invalidatesTags: ['products']
            }),
      


            updateProduct: builder.mutation({
                query: data => {
                    return {
                       url: '/product',
                       method: 'PUT',
                       body: data
                    }
                },
                invalidatesTags: ['products']
            }),
            
            // delete student /delete/student/admin/
            deleteProduct: builder.mutation({
                query: id => {
                    return {
                        url: `/delete/student/admin/${id}`,
                        method: 'DELETE'
                    }
                },
                invalidatesTags: ['products']
            }),
            getProducts: builder.query({
                query: (page) => {
                 return {
                     url: `/products/${page}`,
                     method: 'GET'
                 }
                },
                providesTags: ['products']
            }),
            getProduct: builder.query({
                query: id => {
                return {
                    url: `/product/${id}`,
                    method: 'GET'
                }
                },
                providesTags: ['products']
            }),

            
// admin all student    /dashboard/products
            getStudentsall: builder.query({
                query: (page) => {
                 return {
                     url: `/studentsalladmin/${page}`,
                     method: 'GET'
                 }
                },
                providesTags: ['products']
            }),

            // admin single student 

            getStudentsingle: builder.query({
                query: id => {
                return {
                    url: `/studentsingleadmin/${id}`,
                    method: 'GET'
                }
                },
                providesTags: ['products']
            }),

          // admin single student  update
            toupdateStudent: builder.mutation({
                query: ({ id, ...state }) => ({
                  url: `studentupdateadmin/${id}`,
                  method: 'PUT',
                  body: state,
                }),
              }),

            // admin get teacher all  
              getInstructors: builder.query({
                query: (page) => ({
                  url: `/instructorsalladmin/${page}`,
                  method: 'GET',
                }),
                // providesTags: ['Instructors'],
              }),
// admin get teacher single
              getInstructor: builder.query({
                query: (id) => `/instructor/getsingle/admin/${id}`,
                providesTags: ['Instructor'],
              }),

            //   admin updated teacher
              // updateInstructor: builder.mutation({
              //   query: ({ id, ...instructor }) => ({
              //     url: `/instructor/update/${id}`,
              //     method: 'PUT',
              //     body: instructor,
              //   }),
              //   // invalidatesTags: ['Instructor'],
              // }),

              // //   admin updated teacher
              updateInstructor: builder.mutation({
                query: ({ id, body }) => ({
                  url: `/instructor/update/${id}`,
                  method: 'PUT',
                  body,
                  // headers: {
                  //   'Content-Type': 'multipart/form-data'
                  // }
                }),
                // You might need to invalidate tags or refetch data here
              }),
              // admin search  student
            getStudentsearch: builder.query({
              query: (searchTerm) => {
                  if (!searchTerm) return '/student/admin/search'; // No search term provided
                  return `/student/admin/search?${searchTerm}`; // Use provided search term
              },
          }),
            
            
            
            

             deleteStudentadminsearch: builder.mutation({
              query: (id) => ({
                 url: `/students/${id}`,
                 method: 'DELETE',
              }),
           }),

           getInstructorSearch: builder.query({
            query: (searchTerm) => {
                if (!searchTerm) return '/instructor/admin/search'; // No search term provided
                return `/instructor/admin/search?${searchTerm}`; // Use provided search term
            },
        }),
        deleteInstructorsearch: builder.mutation({
            query: (id) => ({
                url: `/instructor/${id}`,
                method: 'DELETE',
            }),
        }),


        searchCourses: builder.query({
            query: (searchTerm) => `/course/admin/search?${searchTerm}`,
          }),


              deleteInstructor: builder.mutation({
                query: (id) => ({
                  url: `/instructor/${id}`,
                  method: 'DELETE',
                }),
                // invalidatesTags: ['Instructors'],
              }),

              getCoursesAllAdmin: builder.query({
                query: ({ page }) => {
                  return {
                    url: `/get/courses/all/admin/${page}`,
                    method: 'GET'
                  }
                },
                providesTags: ['products']
              }),

             // admin get single Course details
              getSingleCourseAdmin: builder.query({
                query: id => {
                  return {
                    url: `/get/course/single/admin/${id}`,
                    method: 'GET'
                  }
                },
                providesTags: ['products']
              }),

              // admin get single video details
              getSingleVideoAdmin: builder.query({
                query: id => {
                  return {
                    url: `/get/video/single/admin/${id}`,
                    method: 'GET'
                  }
                },
                providesTags: ['products']
              }),




              getadminAllCategories: builder.query({
                query: () => {
                  return {
                    url: "/all/category/admin/bycourse/update",
                    method: "GET",
                  };
                },
              }),

              
              updateCourseAdmin: builder.mutation({
                query: data => {
                  return {
                    url: '/update/course/single/admin',
                    method: 'PUT',
                    body: data
                  }
                },
                invalidatesTags: ['products']
              }),


              // update single video by admin
              updateVideobyAdmin: builder.mutation({
                query: data => {
                  return {
                    url: '/update/video/single/admin',
                    method: 'PUT',
                    body: data
                  }
                },
                invalidatesTags: ['products']
              }),


              createPackage: builder.mutation({
                query: (formData) => ({
                  url: '/create/package/admin/student-packages', // Adjust URL endpoint as needed
                  method: 'POST',
                  body: formData,
                  // No need to set 'Content-Type': 'multipart/form-data' as fetchBaseQuery handles it automatically
                }),
                invalidatesTags: ['Packages'],
              }),

              getPackages: builder.query({
                query: () => '/get/package/admin/student-packages',
              }),
              getPackageById: builder.query({
                query: (id) => `/get/package/byid/admin/student-packages/${id}`,
              }),
              updatePackage: builder.mutation({
                query: ({ id, formData }) => ({
                  url: `/update/package/admin/student-packages/${id}`,
                  method: 'PUT',
                  body: formData,
                }),
              }),





              // payments
              getPaymentMethods: builder.query({
                query: () => '/admin/allpayment',
              }),
              getPaymentMethodById: builder.query({
                query: (id) => `/admin/payment/method/byid/${id}`,
              }),
              createPaymentMethod: builder.mutation({
                query: (newPaymentMethod) => ({
                  url: '/admin/newpayment',
                  method: 'POST',
                  body: newPaymentMethod,
                }),
              }),
              updatePaymentMethod: builder.mutation({
                query: ({ id, ...updatedPaymentMethod }) => ({
                  url: `/admin/update/payment/method/${id}`,
                  method: 'PUT',
                  body: updatedPaymentMethod,
                }),
              }),
              deletePaymentMethod: builder.mutation({
                query: (id) => ({
                  url: `/admin/delete/payment/method/${id}`,
                  method: 'DELETE',
                }),
              }),
        }
    }
})
export const {
  useGetPaymentMethodsQuery,
  useGetPaymentMethodByIdQuery,
  useCreatePaymentMethodMutation,
  useUpdatePaymentMethodMutation,
  useDeletePaymentMethodMutation,
  useGetPackageByIdQuery, useUpdatePackageMutation , useCProductMutation,useGetPackagesQuery ,useCreatePackageMutation,useUpdateCourseAdminMutation,useGetadminAllCategoriesQuery,useSearchCoursesQuery,
  useGetSingleCourseAdminQuery,
  useGetSingleVideoAdminQuery,
  // useUpdateCourseAdminMutation
  useUpdateVideobyAdminMutation,
  useGetCoursesAllAdminQuery   ,useGetInstructorSearchQuery, useDeleteInstructorsearchMutation,useDeleteStudentadminsearchMutation ,useGetStudentsearchQuery,useGetInstructorQuery,useUpdateInstructorMutation ,useDeleteInstructorMutation,useGetInstructorsQuery,useToupdateStudentMutation,useGetStudentsallQuery ,useGetStudentsingleQuery,useDeleteProductMutation , useUpdateProductMutation, useGetProductsQuery, useGetProductQuery
  
  ,useCPdfaAdminMutation
} = productService;
export default productService